import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import titleMixin from './mixins/titleMixin';
import Axios from "axios";
import moment from "moment";
import VueSimpleAlert from "vue-simple-alert";

import 'bootstrap/dist/js/bootstrap.bundle.js';

Vue.prototype.$http = Axios;
Vue.config.productionTip = true;

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

Vue.use(VueSimpleAlert);
Vue.mixin(titleMixin);

import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/style.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
