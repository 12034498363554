<template>
    <div class="d-flex flex-row">
        <h3 class="sub-title text-left flex-grow-1">{{ baslik }}</h3>
        <div class="d-inline-block">
            <button type="button" @click="fontDegis(-1)" class="btn btn-sm btn-outline-dark mr-2">A-</button>
            <button type="button" @click="fontDegis(1)" class="btn btn-sm btn-outline-dark">A+</button>
        </div>

    </div>
</template>

<script>

export default {
    name: "Baslik",
    props:["baslik", "fontOlcek"],
    methods:{
        fontDegis(size){
            console.log('fontDegis', size);
            this.$emit('fontOlcek', size);
            // this.emit('fontOlcek', size);
        }
    }
}
</script>

<style scoped>

</style>
