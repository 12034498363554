<template>
    <div class="my-4">
        <Baslik @fontOlcek="fontOlcek" @fontSize="fontSize" baslik="Faturalar" />


        <table class="table table-sm table-hover table-striped table-bordered">
            <thead>
            <tr class="font-weight-bold">
                <td>Kayıt No</td>
                <td>Tarih</td>
                <td>Tutar</td>
                <td>Kdv Tutar</td>
                <td>Tevkifat Tutar</td>
                <td>Toplam Tutar</td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
                <td colspan="6" class="text-center font-weight-bold">
                    <p class="p-5">Yükleniyor...</p>
                </td>
            </tr>
            <tr class="cpointer" v-for="s in ss" :key="s.id" @click="detay(s.id)">
                <td :style="'font-size: '+fontSize+'px'">{{ s.id }}</td>
                <td :style="'font-size: '+fontSize+'px'">{{ s.tarih | formatDate }}</td>
                <td :style="'font-size: '+fontSize+'px'">{{ s.tutar }}</td>
                <td :style="'font-size: '+fontSize+'px'">{{ s.kdv_tutar }}</td>
                <td :style="'font-size: '+fontSize+'px'">{{ s.tevkifat_tutar }}</td>
                <td :style="'font-size: '+fontSize+'px'">{{ s.ttutar }}</td>
            </tr>
            <tr v-if="!loading && ss.length === 0">
                <td colspan="6" class="text-center font-weight-bold">
                    <p class="p-5">Kayıt Bulunamadı</p>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import axios from "axios";
import moment from 'moment';
import router from "../router";
import Baslik from "@/components/Baslik";

// :class="{ 'bg-info' : s.odeme_yeri == 1 }"

export default {
    name: "Fatura",
    title: "Portal - Fatura",

    components: {Baslik},
    data() {
        return {
            fontSize: 13,
            ss: [],
            loading: false,
        }
    },

    computed: {
        ...mapState(['email', 'password'])
    },

    methods: {
        async load() {
            this.loading = true;
            this.ss = [];

            console.log('load work');

            let params = {email: this.email, password: this.password};
            // axios.post('http://ck.local/api/fatura', params)

            await new Promise(resolve => setTimeout(resolve, 1000));
            axios.post('/api/fatura', params)
                .then(response => {
                    this.loading = false;
                    this.ss = response.data.ss;
                })
                .catch(function (error) {
                    this.loading = false;
                    console.log(error);
                });
        },
        detay(id) {
            console.log('detay ' + id);

            router.push({name: 'FaturaDetay', params: {id: id}});
        },

        fontOlcek(deger) {
            console.log({deger});
            this.fontSize += deger;
        }
    },

    created() {
        setTimeout(() => {
            this.load();
        }, 100);
    }
}
</script>

<style scoped>

</style>
