import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: "",
        is_logged: false,
        email: localStorage.getItem("email") || "",
        password: localStorage.getItem("password") || "",
        newpassword: '',
    },
    setters: {},
    mutations: {
        setEmail(state, value) {
            console.log("email değişti " + value);
            localStorage.setItem("email", value);
            state.email = value;
        },
        setPassword(state, value) {
            localStorage.setItem("password", value);
            state.password = value;
        },

        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state) {
            console.log("auth succ " + state.email + ", " + state.password);
            state.status = "success";
            state.is_logged = true;
            // state.email = email
            // state.password = password
        },
        auth_success2(state, email, password, user) {
            state.status = "success";
            // state.email = email
            // state.password = password
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            state.is_logged = false;
            state.status = "";
            state.email = "";
            state.password = "";
        },
    },
    actions: {
        login({commit, state}) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                console.log("data " + state.email + " " + state.password);
                // commit('auth_success', state.email, state.password)

                if (state.email.length > 3) {
                }

                console.log(
                    "state email " + state.email + ", state pass " + state.password
                );

                var params = {email: state.email, password: state.password};
                console.log("params");
                console.log(params);

                axios
                    //.post("http://ck.local/api/login", params)
                    .post("/api/login", params)
                    .then((resp) => {
                        console.log("login response");
                        console.log(resp.data);
                        if (resp.data.error === false) {
                            // localStorage.setItem('token', token);
                            localStorage.setItem("email", state.email);
                            localStorage.setItem("password", state.password);
                            // axios.defaults.headers.common['Authorization'] = token
                            commit("auth_success", state.email, state.password);
                            resolve(resp);
                        } else {
                            commit("auth_error");
                            localStorage.removeItem("password");
                            reject(resp);
                        }
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("password");
                        // localStorage.removeItem('token')
                        reject(err);
                    });
            });
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit("logout");
                // localStorage.removeItem('email');
                localStorage.removeItem("password");
                delete axios.defaults.headers.common["Authorization"];
                resolve();
            });
        },
        sifreDegis({commit, state}, newpassword) {
            return new Promise((resolve, reject) => {

                var params = {email: state.email, password: state.password, newpassword: newpassword};

                console.log("params");
                console.log(params);
                //reject(false);

                axios
                    //.post("http://ck.local/api/sifre-degis", params)
                    .post("/api/sifre-degis", params)
                    .then((resp) => {
                        console.log("sifre degis response");
                        console.log(resp.data);
                        if (resp.data.error === false) {
                            localStorage.setItem("password", newpassword);
                            resolve(resp);
                        } else {
                            reject(resp);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    modules: {},
});
