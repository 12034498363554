<template>
    <div class="col-md-12 col-lg-10 col-xl-7 col-12 nofl mauto">
        <h1>Fatura Detay {{ s.id }}</h1>
        <table class="table table-bordered">
           <tr>
               <td class="text-right" width="40%">Kayıt No</td>
               <td class="text-left">{{ s.id }}</td>
           </tr>
           <tr>
               <td class="text-right">Tarih</td>
               <td class="text-left">{{ s.tarih | formatDate }}</td>
           </tr>
        </table>
        <hr>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <td>Açıklama</td>
                    <td>Miktar</td>
                    <td>Birim Fiyat</td>
                    <td>Tutar</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="c in s.kalems" :key="c.id">
                    <td>{{ c.acik }}</td>
                    <td>{{ c.adet }}</td>
                    <td>{{ c.fiyat }}</td>
                    <td>{{ c.tutar }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="3" class="text-right">TUTAR</th>
                    <th>{{ s.tutar }}</th>
                </tr>
                <tr>
                    <th colspan="3" class="text-right">KDV % 18</th>
                    <th>{{ s.kdv_tutar }}</th>
                </tr>
                <tr v-if="s.tevkifat_tutar > 0">
                    <th colspan="3" class="text-right">TEVKİFAT % 20</th>
                    <th>{{ s.tevkifat_tutar }}</th>
                </tr>
                <tr>
                    <th colspan="3" class="text-right">TOPLAM TUTAR</th>
                    <th>{{ s.ttutar }}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>

import {mapActions,mapState} from "vuex";
import axios from "axios";
import moment from 'moment';
import router from "../router";

// :class="{ 'bg-info' : s.odeme_yeri == 1 }"

export default {
    name: "FaturaDetay",
    data(){
        return {
            s:{
                id: '-',
                tarih: '-',
                tutar: '-',
                kdv_tutar: '-',
                tevkifat: false,
                tevkifat_tutar : '-',
                iskonto: '-',
                ttutar: '-',
                kalems: [
                    {
                        ambalaj: '-',
                        adet: '-',
                        fiyat: '-',

                    }
                ]
            }
        }
    },

    computed: {
        ...mapState(['email', 'password'])
    },

    methods:{
        async load(){
            this.s = {};
            console.log('load work');
            let params = {id: this.$route.params.id, email: this.email, password: this.password};
            // axios.post('http://ck.local/api/fatura-detay', params)
            axios.post('/api/fatura-detay', params)
            .then(response => (this.s = response.data.s))
            .catch(function (error) {
                console.log(error);
            });
        },
    },
    filters: {
        formatMoney(s, f) {
            return !isNaN(s) && s !== '' && typeof s === 'number' ? s.toFixed(f)
                .toString()
                .replace('.', ',')
                .replace(/\d(?=(\d{3})+,)/g, '$&.') : '';
        }
    },

    created() {
       this.load();
    }
}
</script>

<style scoped>

</style>
