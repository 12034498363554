<template>
    <div class="col-md-12 col-lg-10 col-xl-7 col-12 nofl mauto my-2">
        <h3 class="sub-title mt-4">Fiş Detay {{ this.$route.params.id }}</h3>

        <div class="p-4 font-weight-bold" v-if="loading">
            Yükleniyor...
        </div>

        <div class="table-responsive" v-if="!loading">
            <table class="table table-sm table-bordered">
                <tr>
                    <td class="text-right" style="width: 40%">Fiş No</td>
                    <td class="text-left">{{ s.id }}</td>
                </tr>
                <tr>
                    <td class="text-right">Tarih</td>
                    <td class="text-left">{{ s.tarih | formatDate }}</td>
                </tr>
                <tr>
                    <td class="text-right">Gönderen</td>
                    <td class="text-left">{{ s.gonderen_ad }}</td>
                </tr>
                <tr>
                    <td class="text-right">Alıcı</td>
                    <td class="text-left">{{ s.alici_ad }}</td>
                </tr>
                <tr>
                    <td class="text-right">Ödeme Yeri</td>
                    <td class="text-left">{{ s.odeme_yeri }}</td>
                </tr>
            </table>
            <hr>
            <table class="table table-sm table-bordered">
                <thead>
                <tr class="font-weight-bold">
                    <td>Ambalaj Tipi</td>
                    <td>Adet</td>
                    <td>Fiyat</td>
                    <td>Kdv Tutar</td>
                    <td>Tevkifat Tutar</td>
                    <td>Tutar</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="c in s.cuvals" :key="c.id">
                    <td>{{ c.ambalaj }}</td>
                    <td>{{ c.adet }}</td>
                    <td>{{ c.fiyat }}</td>
                    <td>{{ c.kdv_tutar }}</td>
                    <td>{{ c.tevkifat_tutar }}</td>
                    <td>{{ c.tutar }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th>Toplam</th>
                    <th>{{ s.toplam_cuval }}</th>
                    <th></th>
                    <th>{{ s.kdv_tutar > 0 ? s.kdv_tutar : '' }}</th>
                    <th>{{ s.tevkifat_tutar > 0 ? s.tevkifat_tutar : '' }}</th>
                    <th>{{ s.genel_toplam_tutar > 0 ? s.genel_toplam_tutar * 1 : '' }}</th>
                </tr>
                </tfoot>
            </table>

        </div>
        <div class="teslim-fis-resim" v-if="!loading">
            <hr />
            <h3 class="sub-title text-under"><u>Kargo Teslim Fişleri</u></h3>
            <div class="col-12">


                <div class="image-thumb" v-for="m in s.mm" :key="m.id">
<!--                    <a :href="'http://sistem.cktasimacilik.com/uploads/'+m.src" target="_blank">
                        <img :src="'http://sistem.cktasimacilik.com/uploads/'+m.src" alt="" />
                    </a>-->

                    <a :href="'/tim/'+m.src" target="_blank">
                        <img :src="'/tim/'+m.src" alt="" />
                    </a>
                </div>

                <h5 v-if="s.mm.length === 0">Hiç teslim fiş resmi yok</h5>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import axios from "axios";
import moment from 'moment';
import router from "../router";

// :class="{ 'bg-info' : s.odeme_yeri == 1 }"

export default {
    name: "FisDetay",
    data() {
        return {
            https : window.location.protocol === 'https:',
            prefix: window.location.protocol === 'https:' ? 'https://' : 'http://',
            s: {
                id: '-',
                tarih: null,
                gonderen: '-',
                alici: '-',
                odeme_yer: '-',
                cuvals: [
                    {
                        ambalaj: '-',
                        adet: '-',
                        fiyat: '-',

                    }
                ],
                mm: [],
            },
            loading: false,
        }
    },

    computed: {
        ...mapState(['email', 'password'])
    },

    methods: {
        async load() {
            // this.s = {};
            this.loading = true;
            var id = this.$route.params.id;


            console.log('load work');

            let params = {id: id, email: this.email, password: this.password};
            // axios.post('http://ck.local/api/fis-detay', params)
            axios.post('/api/fis-detay', params)
                .then(response => {
                    this.s = response.data.s;
                    this.loading = false;
                })
                .catch(function (error) {
                    this.loading = false;
                    console.log(error);
                });
        },
    },
    filters: {
        formatMoney(s, f) {
            return !isNaN(s) && s !== '' && typeof s === 'number' ? s.toFixed(f)
                .toString()
                .replace('.', ',')
                .replace(/\d(?=(\d{3})+,)/g, '$&.') : '';
        }
    },

    created() {
        this.load();
    }
}
</script>

<style scoped>
.image-thumb {
    position: relative;
    padding-bottom: 30px;
    height: 200px;
    width: 300px;
    margin: 10px;
}

.image-thumb img {
    max-width: 100%;
}
</style>
