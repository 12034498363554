<template>

    <div class="container">

        <form class="form-signin">
            <img class="mb-4" src="@/assets/logo2.png" alt="logo">
            <h1 class="h3 mb-3 font-weight-normal">Oturum Aç</h1>
            <label for="inputEmail" class="sr-only">Eposta</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="Eposta" required autofocus v-model="email">
            <label for="inputPassword" class="sr-only">Parola</label>
            <input type="password" id="inputPassword" class="form-control" placeholder="Parola" required v-model="password">
            <div class="checkbox mb-3">
                <label>
                    <input type="checkbox" value="remember-me"> Beni Hatırla
                </label>
            </div>
            <button class="btn btn-lg btn-dark btn-block" type="button" @click="handleSubmit">Oturum Aç</button>
            <p class="mt-5 mb-3 text-muted">&copy; {{yil}}</p>
        </form>
    </div>

</template>

<script>
import router from "../router";
import {mapActions,mapState} from "vuex";

export default {
    name: "Login",
    title: "CK - Portal - Giriş",
    data(){
        return {
            // btnText : 'Giriş Yap',
            oturumAciliyor: false
        }

    },
    computed: {
        yil(){ return (new Date()).getFullYear();},
        btnText(){ return this.oturumAciliyor === true ? 'Giriş Yapılıyor' : 'Giriş Yap'; },
        email: {
            get(){ return this.$store.state.email; },
            set(value){ this.$store.commit('setEmail', value);},
        },
        password: {
            get(){ return this.$store.state.password; },
            set(value){ this.$store.commit('setPassword', value);},
        }
    },

    methods: {
        ...mapActions(['login']),

        handleSubmit(e) {
            e.preventDefault();
            if(!this.oturumAciliyor){
                // this.btnText = 'Giriş Yapılıyor...';
                this.oturumAciliyor = true;
                this.login()
                .then(response =>{

                    console.log('Giriş Yapıldı');
                    this.oturumAciliyor = false;
                    router.push({name: 'Home'});

                }).catch((err) => {

                    console.log('Hata Oluştu');

                    if(typeof err !== 'undefined'){
                        console.log(err);
                    }else{
                        console.log('err undefinedd');
                    }
                    // this.btnText = 'Giriş Yapılıyor...';
                    this.oturumAciliyor = false;
                    this.$alert(err.data.msg, 'HATA', 'error');
                    // console.log(err);
                });
            }


            /*
            this.$store.this.$emit('login')
            router.push({name: 'Home'});
            */
        }
    }
}
</script>

<style scoped>

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
