<template>
    <div class="col-md-12 col-lg-10 col-xl-8 col-12 nofl mauto my-4">


        <Baslik @fontOlcek="fontOlcek" @fontSize="fontSize" baslik="Hesap Hareketleri" />

        <div class="p-4 font-weight-bold" v-if="loading">
            Yükleniyor
        </div>
        <div class="table-responsive" v-if="!loading">
            <table class="table table-bordered">
                <tr>
                    <!--
                    <td class="text-center">Alacak</td>
                    <td class="text-center">Borç</td>
                    -->
                    <td class="text-center font-weight-bold">Bakiye</td>
                    <td class="text-center font-weight-bold"
                        :class="s.bakiye < 0 ? 'text-danger' : (s.bakiye > 0 ? 'text-success' : '')">{{ s.bakiye }}
                    </td>
                </tr>
                <!--
               <tr>
                   <td class="text-center font-weight-bold">{{ s.alacak }}</td>
                   <td class="text-center font-weight-bold">{{ s.borc }}</td>
               </tr>
               -->
            </table>
            <hr>

            <table class="table table-sm table-striped table-hover table-bordered">
                <thead>
                <tr class="font-weight-bold">
                    <td>Tarih</td>
                    <td>İşlem</td>
                    <td>Açıklama</td>
                    <td>Tutar</td>
                    <td>Bakiye</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="c in s.islems" :key="c.id">
                    <td :style="'font-size: '+fontSize+'px'">{{ c.tarih | formatDate }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ c.ad }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ c.acik }}</td>
                    <td :style="'font-size: '+fontSize+'px'" class="font-weight-bold" :class="{' text-success' : c.is_giren === true }">{{ c.tutar }}</td>
                    <td :style="'font-size: '+fontSize+'px'" class="font-weight-bold">{{ c.bakiye }}</td>
                </tr>
                </tbody>

            </table>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import axios from "axios";
import moment from 'moment';
import router from "../router";
import Baslik from "@/components/Baslik";

// :class="{ 'bg-info' : s.odeme_yeri == 1 }"

export default {
    name: "Hesap",
    components: {Baslik},
    title: "Portal - Hesap Hareketleri",
    data() {
        return {
            fontSize: 13,
            loading: false,
            s: {
                id: '-',
                alacak: '-',
                borc: '-',
                bakiye: '-',
                islems: []
            }
        }
    },

    computed: {
        ...mapState(['email', 'password'])
    },

    methods: {
        async load() {
            this.loading = true;
            this.s = {};
            console.log('load work');
            let params = {email: this.email, password: this.password};
            // axios.post('http://ck.local/api/hesap-detay', params)
            axios.post('/api/hesap-detay', params)
                .then(response => {
                    this.loading = false;
                    this.s = response.data.s;
                })
                .catch(function (error) {
                    this.loading = false;
                    console.log(error);
                });
        },

        fontOlcek(deger) {
            console.log('hesap font olcek work');
            this.fontSize += deger;
        }
    },
    filters: {
        formatMoney(s, f) {
            return !isNaN(s) && s !== '' && typeof s === 'number' ? s.toFixed(f)
                .toString()
                .replace('.', ',')
                .replace(/\d(?=(\d{3})+,)/g, '$&.') : '';
        }
    },

    created() {
        this.load();
    }
}
</script>

<style scoped>
</style>
