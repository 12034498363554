<template>
    <div class="my-4">

        <Baslik @fontOlcek="fontOlcek" @fontSize="fontSize" baslik="Fişler" />


        <div class="clear"></div>




        <div class="table-responsive">
            <table class="table table-sm table-hover table-striped table-bordered">
                <thead>
                <tr class="font-weight-bold">
                    <td>Fiş No</td>
                    <td>Tarih</td>
                    <td>Alıcı</td>
                    <td>Gönderici</td>
                    <td>Ödeme Yeri</td>
                    <td>Kargo Teslim Fişi</td>
                    <td>Adet</td>
                    <td>Tutar</td>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="8" class="text-center font-weight-bold">
                        <p class="p-5">Yükleniyor...</p>
                    </td>
                </tr>
                <tr class="cpointer" v-for="s in ss" :key="s.id" @click="detay(s.id)">
                    <td :style="'font-size: '+fontSize+'px'">{{ s.id }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ s.tarih | formatDate }}</td>
                    <td :style="'font-size: '+fontSize+'px'" class="text-left">{{ s.alici_ad }}</td>
                    <td :style="'font-size: '+fontSize+'px'" class="text-left">{{ s.gonderen_ad }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ s.odeme_yeri }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ s.mm.length }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ s.toplam_cuval }}</td>
                    <td :style="'font-size: '+fontSize+'px'">{{ s.genel_toplam_tutar }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import axios from "axios";
import moment from 'moment';
import router from "../router";
import Baslik from "@/components/Baslik";

// :class="{ 'bg-info' : s.odeme_yeri == 1 }"

export default {
    name: "Fis",
    components: {Baslik},
    title: "Portal - Fişler",
    data() {
        return {
            fontSize: 13,
            ss: [],
            loading: false,
        }
    },

    computed: {
        ...mapState(['email', 'password'])
    },

    methods: {
        async load() {
            this.ss = [];
            this.loading = true;

            console.log('load work');

            let params = {email: this.email, password: this.password};
            //axios.post('http://ck.local/api/fis', params)
            axios.post('/api/fis', params)
                .then(response => {
                    this.ss = response.data.ss;
                    this.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loading = false;
                });
        },
        detay(id) {
            console.log('detay ' + id);

            router.push({name: 'FisDetay', params: {id: id}});
        },
        fontOlcek(deger) {
            console.log({deger});
            this.fontSize += deger;
        }
    },

    created() {
        setTimeout(() => {
            this.load();
        }, 100);
    }
}
</script>

<style scoped>

</style>
