import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Sifre from "../views/SifreDegis.vue";
import Fatura from "../views/Fatura.vue";
import FaturaDetay from "../views/FaturaDetay.vue";
import Fis from "../views/Fis.vue";
import FisDetay from "../views/FisDetay.vue";
import Hesap from "../views/Hesap.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

var reqAuth = true;
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/hesap",
    name: "Hesap",
    component: Hesap,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/sifre-degis",
    name: "Sifre",
    component: Sifre,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/fatura",
    name: "Fatura",
    component: Fatura,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/fatura-detay/:id",
    name: "FaturaDetay",
    component: FaturaDetay,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/fis",
    name: "Fis",
    component: Fis,
    meta: {
      requiresAuth: reqAuth,
    },
  },
  {
    path: "/fis-detay/:id",
    name: "FisDetay",
    component: FisDetay,
    meta: {
      requiresAuth: reqAuth,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

    linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.is_logged) {
      console.log("islogged");
      next();
      return;
    } else {
      console.log("login olmalı ");
    }
    next("/login");
  } else {
    console.log("login gerekli değil");
    next();
  }
});

export default router;
