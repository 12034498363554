<template>
  <div id="app" class="container">
      <!--
      <div id="nav" v-if="is_logged">
          <router-link to="/">Anasayfa</router-link> |
          <router-link to="/fis">Fişler</router-link> |
          <router-link to="/hesap">Hesap</router-link> |
          <router-link to="/fatura">Fatura</router-link> |
          <router-link to="/sifre-degis">Şifre Değiş</router-link> |
          <a href="#" @click="logout">Çıkış</a>
      </div>
      -->

      <nav class="navbar navbar-expand-lg navbar-dark bg-dark" v-if="is_logged">
          <router-link class="navbar-brand" to="/" exact>CK</router-link>
          <button class="navbar-toggler" type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                      <router-link class="nav-link" to="/" exact>Anasayfa</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link class="nav-link" to="/fis">Fişler</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link class="nav-link" to="/hesap">Hesap</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link class="nav-link" to="/fatura">Fatura</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link class="nav-link" to="/sifre-degis">Şifre Değiş</router-link>
                  </li>
                  <li class="nav-item">
                      <a href="#" class="nav-link" @click="logout">Çıkış</a>
                  </li>

              </ul>
              <form class="form-inline my-2 my-lg-0 d-none">
                  <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                  <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
              </form>
          </div>
      </nav>
      <div class="clearfix"></div>
    <router-view/>
  </div>
</template>

<script>

import {mapActions,mapState} from "vuex";

export default {
    data(){
        return {

        }
    },
    computed : {
        ...mapState(['is_logged']),
    },
    methods: {

        logout: function () {
            console.log('logout working');
            this.$store.dispatch('logout')
                .then(() => {
                    console.log('logout ==> isLogged '+this.is_logged);
                    console.log('redirecting login');
                    this.$router.push({ name: 'Login'});
                })

        }
    },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
