<template>
    <div class="p-5 d-flex flex-column justify-content-center align-items-center">

        <h2 class="mb-2">Hoş Geldiniz</h2>
        <h4>Üst menüden fiş, fatura, hesap detayı görüntüleyebilirsiniz</h4>
        <div v-show="acik">
            <div class="alert alert-warning" role="alert">
                <h4 class="alert-heading">Bildiri</h4>
                <p>{{ acik }}</p>
                <hr>
                <p class="mb-0">&nbsp;</p>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions,mapState} from "vuex";
import axios from "axios";
import router from "@/router";
export default {
    name: "Home",
    title: "Portal - Anasayfa",
    data() {
        return {
            acik: '',
        }
    },
    computed: {
        ...mapState(['email', 'password'])
    },
    methods: {
        async info() {
            this.ss = [];

            console.log('load work');

            let params = {email: this.email, password: this.password};
            axios.post('/api/portal-acik', params)
                .then(response => (this.acik = response.data.acik))
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    created() {
        this.info();
    }
}
</script>

<style scoped>

</style>
