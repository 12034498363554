<template>
    <div class="col-md-6 my-4 nofl mauto">
        <h3 class="sub-title">Şifre Değiştirme</h3>
        <form>
            <div class="form-group">
                <label>Yeni Şifreniz</label>
                <input type="text" class="form-control" v-model="yeni_sifre"/>
            </div>

            <div class="form-group">
                <label>Yeni Şifre Tekrarı</label>
                <input type="text" class="form-control" v-model="yeni_sifrer"/>
            </div>

            <button type="submit" class="btn btn-dark btn-block" @click="handleSubmit">{{ btnText }}</button>
        </form>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import router from "@/router";

export default {
    name: "Sifre",
    title: "Portal - Şifre Değiş",
    data() {
        return {
            yeni_sifre: '',
            yeni_sifrer: '',
            saving: false,
        }
    },
    computed: {
        btnText() {
            return this.saving === true ? 'Değiştiriliyor' : 'Değiştir';
        },
    },
    methods: {

        ...mapActions(['sifreDegis']),

        handleSubmit(e) {
            e.preventDefault();

            if (!this.saving) {
                console.log('yeni sifre '+this.yeni_sifre);
                this.sifreDegis(this.yeni_sifre)
                    .then(response => {

                        console.log('Giriş Yapıldı');
                        this.saving = false;

                        if (response.data.error === false) {
                            this.$alert(response.data.msg, 'Şifre Değiştirme', 'success');

                            setTimeout(function () {
                                router.push({name: 'Home'});
                            }, 1500)
                        }


                    }).catch((err) => {

                    console.log('Hata Oluştu');

                    if (typeof err !== 'undefined') {
                        console.log(err);
                    } else {
                        console.log('err undefinedd');
                    }
                    this.saving = false;
                    this.$alert(err.data.msg, 'HATA', 'error');
                });
            }

        }
    }
}
</script>

<style scoped>

</style>
